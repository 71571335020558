import { Injectable } from '@angular/core';
import { BazisChatService } from '@bazis/chat/shared/services/chat.service';
import { BazisEntityService } from '@bazis/shared/services/entity.service';
import { BazisSrvService } from '@bazis/shared/services/srv.service';
import { BazisAuthService } from '@bazis/shared/services/auth.service';
import { BazisWebSocketService } from '@bazis/shared/services/web-socket.service';
import { OrganizationService } from '@pages/shared/services/organization.service';
import { ROLE } from '@app/configuration.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class AgroChatService extends BazisChatService {
    generalSettings = {
        participantsSettings: [
            {
                id: 'customer',
                groupTitleKey: 'chat.participants.customer',
                sourceField: 'autogen_customer_users_selectors',
                roles: [ROLE.client],
            },
            {
                id: 'executor',
                groupTitleKey: 'chat.participants.executor',
                sourceField: 'autogen_executor_users_selectors',
                roles: [ROLE.executor],
            },
        ],
        addParticipantSettings: {
            filterByUserOrganization: true,
            filterParams: null,
            sort: null,
            entityType: null,
        },
        chatTitle$: (chat) => {
            return this.entityService
                .getEntity$(chat.$snapshot.context_model, chat.$snapshot.context_id)
                .pipe(
                    map((bid) => {
                        const name = [];
                        if (bid.$snapshot.org_owner_info) {
                            name.push(bid.$snapshot.org_owner_info.name);
                        }
                        if (bid.$snapshot.org_owner_executor_info) {
                            name.push(bid.$snapshot.org_owner_executor_info.name);
                        }
                        return {
                            action: null,
                            name: name.join(', '),
                        };
                    }),
                );
        },
    };

    constructor(
        protected entityService: BazisEntityService,
        protected srvService: BazisSrvService,
        protected authService: BazisAuthService,
        protected socketService: BazisWebSocketService,
        protected organizationService: OrganizationService,
    ) {
        super(entityService, srvService, authService, socketService);
    }

    getChatContextUrl(contextModel, contextId, role = null) {
        const contextModelsToUrls = {
            'long_term.contract': (contextId) => '/contracts/' + contextId,
        };

        return contextModelsToUrls[contextModel]
            ? contextModelsToUrls[contextModel](contextId)
            : null;
    }

    openOrganizationData(organizationId) {
        this.organizationService.openRequisites(organizationId);
    }
}

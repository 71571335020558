import { Injectable } from '@angular/core';
import { shareReplay } from 'rxjs';
import { map } from 'rxjs/operators';
import { buildFilterStr, convertMbToBytes, flipObject, latinizeStr } from '@bazis/utils';
import { IsActiveMatchOptions } from '@angular/router';
import { MaskSettings } from '@bazis/form/models/form-element.types';
import { BazisConfigurationService } from '@bazis/configuration.service';
import * as bazisConfig from '@bazis/configuration.service';

export const API_URL = '/api/web/v1';
export const DEFAULT_LIST_LIMIT = 20;
export const ALL_LIST_LIMIT = 1000;

export const AVAILABLE_LANGUAGES = ['ru'];
export const SELECTED_LANGUAGE =
    AVAILABLE_LANGUAGES.indexOf(localStorage.getItem('lang')) > -1
        ? localStorage.getItem('lang')
        : 'ru';

export const API_DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ssZ';
export const API_DATETIME_FORMAT_Z = 'YYYY-MM-DD[T]HH:mm:ss[Z]';
export const FILTER_DATE_FORMAT = 'YYYY-MM-DD';
export const VAT = 20;

export const SUCCESS_LOGIN_PAGE = '/success-login';
export const SUCCESS_INVITE_PAGE = '/success-invite';

export const MAX_FILE_SIZE = convertMbToBytes(10); // 10мб

export const FAQ_TG_CHANNEL_LINK = 'https://t.me/AgroGO_support';
export const TG_BOT = '@AgroGo_bot';
export const TG_COMMAND = '/start';

export const DOCUMENT_LINKS = {
    privacyPolicy: '/legal-documents/privacy_policy',
    userAgreement: '/legal-documents/site_rules',
    cookiePolicy: '/legal-documents/cookie_policy',
    agencyContract: '/legal-documents/agency_contract',
    partnerContract: '/legal-documents/partner_contract',
};

export const UNDERCONSTRUCTION_TITLE = {
    feature: 'title.featureUnderconstruction',
    section: 'title.sectionUnderconstruction',
};

export const OPERATOR_REPORT_SETTINGS = {
    startYear: 2022,
    startMonth: 11,
    filterTimeCorrection: -3,
};

export const DATETIME_LOCALE = bazisConfig.DATETIME_LOCALE;

export const ROLE = {
    executor: 'AGRO_LK_EXECUTOR',
    client: 'AGRO_LK_CUSTOMER',
    systemOperator: 'AGRO_LK_ADMIN',
    anonymous: 'AGRO_LK_PUBLIC_USER',
    base: 'AGRO_LK_BASE_USER',
    director: 'AGRO_LK_SUPERVISOR',
    empty: null,
};

export const ROLE_ACCOUNT_TYPE = {
    [ROLE.client]: 'customer',
    [ROLE.executor]: 'executor',
    [ROLE.systemOperator]: 'executor',
};

export const DISABLE_NOTIFICATIONS_ROLES = [ROLE.anonymous, ROLE.base];

export const COLORS = {
    primary: '#00BC41',
    secondary: '#43b1f2',
    tertiary: '#817af9',
    action: '#004064',
    info: '#C4C4C4',
    success: '#00bc41',
    warning: '#F6BF36',
    danger: '#ED1A34',
    light: '#f4f5f8',
    medium: '#92949c',
    dark: '#111214',
};

export const DEFAULT_POLYGON_SETTINGS = {
    stroke: true,
    weight: 2,
    color: '#00BC41',
    fill: true,
    fillColor: '#00BC41',
    fillOpacity: 0.1,
};

export const AUTH_PAGE_URL = '/login';
export const PERSONAL_INFO_PAGE_URL = '/profile/personal-info';

export const API_DOC_LANGS_MAP = {
    ru: 'ru',
};

// TODO: models for search
export const ALL_SEARCH_MODELS = [
    {
        id: 'all',
        nameKey: 'search.all',
    },
    {
        id: 'long_term.contract',
        nameKey: 'search.contracts',
        urlStart: '/contracts/',
    },
    {
        id: 'long_term.contractorder',
        nameKey: 'search.orders',
        urlStart: '/orders/',
    },
    {
        id: 'fulfilment.carriertask',
        nameKey: 'search.tasks',
        urlStart: '/carrier-tasks/',
    },
    {
        id: 'waste_source.wasteplace',
        nameKey: 'search.wasteplace',
        urlStart: '/wasteplaces/',
    },
    {
        id: 'facility.facility',
        nameKey: 'search.facility',
        urlStart: '/facilities/',
    },
    {
        id: 'carrier.vehicle',
        nameKey: 'search.vehicle',
        urlStart: '/vehicles/',
    },
];

export const ROLE_SEARCH_MODEL_IDS = {
    //[ROLE.executor]: ALL_SEARCH_MODELS.map((v) => v.id),
    [ROLE.executor]: [],
    [ROLE.client]: [],
    [ROLE.systemOperator]: [],
    [ROLE.anonymous]: [],
    [ROLE.base]: [],
    [ROLE.director]: [],
    [ROLE.empty]: [],
};

export const ROLE_REVERT = flipObject(ROLE);

export const SHARE_REPLAY_SETTINGS = { bufferSize: 1, refCount: true };

export const PHONE_MASK = bazisConfig.PHONE_MASK;
export const PHONE_PATTERN = bazisConfig.PHONE_PATTERN;

export const VEHICLE_MASK_SETTINGS: Partial<MaskSettings> = {
    ...bazisConfig.VEHICLE_MASK_SETTINGS,
    patterns: {
        ...bazisConfig.VEHICLE_MASK_SETTINGS.patterns,
        V: {
            pattern: new RegExp('^[abekmhopctyxавекмнорстухABEKMHOPCTYXАВЕКМНОРСТУХ1234567890]'),
        },
        '9': {
            pattern: new RegExp('\\d'),
            optional: true,
        },
    },
};

export const PHONE_MASK_SETTINGS: Partial<MaskSettings> = {
    pipeMask: '+7 000 000-00-00',
    mask: '0 000 000-00-00',
    prefix: '+',
    placeHolderCharacter: '_',
    dropSpecialCharacters: [' ', '-', '+'],
    validation: false,
    keepCharacterPositions: false,
    showMaskTyped: true,
    outputTransformFn: (value) => {
        return value ? `+${value}` : '';
    },
};

export const PHONE_INPUT_TRANSFORM_FN = (phone: string) =>
    phone && phone.indexOf('+') > -1 ? phone.split('+').join('') : phone;

export const PHONE_PIPE_SETTINGS = {
    pattern: PHONE_MASK_SETTINGS.mask,
    prefix: PHONE_MASK_SETTINGS.prefix,
    transform: PHONE_INPUT_TRANSFORM_FN,
};

export const COMMON_DATE_MASK_SETTINGS = bazisConfig.COMMON_DATE_MASK_SETTINGS;

export const SNILS_PATTERN = bazisConfig.SNILS_PATTERN;
export const SNILS_MASK_SETTINGS = bazisConfig.SNILS_MASK_SETTINGS;

export const BIC_PATTERN = bazisConfig.BIC_PATTERN;
export const RRC_PATTERN = bazisConfig.RRC_PATTERN;
export const INN_PATTERN = bazisConfig.INN_PATTERN;
export const BANK_ACCOUNT_PATTERN = bazisConfig.BANK_ACCOUNT_PATTERN;
export const CORRESPONDENT_ACCOUNT_PATTERN = bazisConfig.CORRESPONDENT_ACCOUNT_PATTERN;
export const OKTMO_MASK_SETTINGS = bazisConfig.OKTMO_MASK_SETTINGS;

export const CADASTRAL_NUMBER_PATTERN = /\d{2}:\d{2}:\d{1,7}:\d{1,}/;

export const FKKO_UNIVERSAL_FILTER = { class_fkko: [3, 4, 5] };
export const AVG_SPEED = 50;

export const FKKO_CLASS_OPTIONS_LIST = [
    {
        id: '3',
        nameKey: 'title.classNumber',
        nameParams: { number: 3 },
    },
    {
        id: '4',
        nameKey: 'title.classNumber',
        nameParams: { number: 4 },
    },
    {
        id: '5',
        nameKey: 'title.classNumber',
        nameParams: { number: 5 },
    },
];

export const FORM_SAVE_BUTTON_SETTINGS = {
    titleKey: 'action.save',
};

export const MOBILE_ROLES = [];

export const HEADER_MENU = {
    [ROLE.anonymous]: [
        {
            titleKey: 'header.requests',
            url: '/lots',
        },
        {
            titleKey: 'header.contacts',
            url: '/about/contacts',
        },
        {
            titleKey: 'header.infoCenter',
            url: '/',
            children: [
                {
                    titleKey: 'header.about',
                    url: '/about',
                },
                {
                    titleKey: 'header.news',
                    url: '/news',
                },
                {
                    titleKey: 'header.faq',
                    url: '/faq',
                },
            ],
        },
    ],
    [ROLE.base]: [
        {
            titleKey: 'header.userInitial',
            url: '/user-initial',
        },
    ],
    [ROLE.director]: [
        {
            titleKey: 'header.companyUsers',
            url: '/director/users',
        },
        // {
        //     titleKey: 'header.roleRequests',
        //     url: '/director/requests',
        // },
    ],
    [ROLE.client]: [
        {
            titleKey: 'header.addRequest',
            url: '/draft/bid/new/',
        },
        {
            titleKey: 'header.fields',
            url: '/fields',
        },
        {
            titleKey: 'header.requests',
            url: '/bids',
        },
        {
            titleKey: 'header.orders',
            url: '/contracts',
        },
    ],
    [ROLE.executor]: [
        {
            titleKey: 'header.myVehicles',
            url: '/vehicles',
            children: [
                {
                    titleKey: 'header.myVehicleList',
                    url: '/vehicles',
                },
                {
                    titleKey: 'header.setupTelemetry',
                    url: '/setup-telemetry',
                },
            ],
        },
        {
            titleKey: 'header.requests',
            url: '/lots',
        },
        {
            titleKey: 'header.orders',
            url: '/contracts',
        },
    ],
    [ROLE.systemOperator]: [
        {
            titleKey: 'header.organizations',
            url: '/organizations',
        },
        {
            titleKey: 'header.requests',
            url: '/bids',
        },
        {
            titleKey: 'header.orders',
            url: '/contracts',
        },
        {
            titleKey: 'header.more',
            url: '/',
            type: 'more',
            children: [
                {
                    titleKey: 'header.fields',
                    url: '/fields',
                },
                {
                    titleKey: 'header.vehicles',
                    url: '/vehicles',
                },
                {
                    titleKey: 'header.lots',
                    url: '/lots',
                },
                {
                    titleKey: 'header.reviews',
                    url: '/reviews',
                },
                {
                    titleKey: 'header.operatorReports',
                    url: '/operator-reports',
                    queryParams: { status: 'draft' },
                },
                // {
                //     titleKey: 'header.roleRequests',
                //     url: '/director/requests',
                // },
                {
                    titleKey: 'header.summary',
                    url: '/summary',
                },
                {
                    titleKey: 'header.analytics',
                    url: '/analytics',
                },
                {
                    titleKey: 'header.companyUsers',
                    url: '/director/users',
                },
                {
                    titleKey: 'header.agrooperationBids',
                    url: '/agrooperation-bids',
                },
            ],
        },
    ],
    [ROLE.empty]: [{}],
};

export const USER_MENU = [
    {
        titleKey: 'header.personalInfo',
        url: '/profile/personal-info',
        icon: 'portrait',
        excludeForRoles: [],
    },
    {
        titleKey: 'header.account',
        url: '/profile/account',
        icon: 'credit-card',
        excludeForRoles: [ROLE.base, ROLE.director, ...MOBILE_ROLES],
    },
    {
        titleKey: 'header.notifications',
        url: '/profile/notifications',
        icon: 'bell',
        excludeForRoles: [ROLE.base, ROLE.director],
    },
    {
        type: 'roles',
        titleKey: 'header.enterAs',
        icon: 'arrow-right',
        excludeForRoles: [],
    },
    {
        titleKey: 'header.logout',
        url: '/logout',
        icon: 'power',
        excludeForRoles: [],
    },
];

export const USER_STATUSES = {
    active: {
        color: 'success',
        nameKey: 'director.users.status.active',
    },
    waiting: {
        color: 'secondary',
        nameKey: 'director.users.status.waiting',
    },
    blocked: {
        color: 'error',
        nameKey: 'director.users.status.blocked',
    },
};

export const FOOTER_MENU = {
    [ROLE.client]: [
        {
            titleKey: 'footer.addRequest',
            url: '/draft/bid/new',
        },
        {
            titleKey: 'footer.requests',
            url: '/bids',
        },
        {
            titleKey: 'footer.account',
            url: '/profile/account',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.executor]: [
        {
            titleKey: 'footer.account',
            url: '/profile/account',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.systemOperator]: [
        {
            titleKey: 'footer.account',
            url: '/profile/account',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.base]: [
        {
            titleKey: 'footer.personalInfo',
            url: '/profile/personal-info',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
    [ROLE.director]: [
        {
            titleKey: 'footer.personalInfo',
            url: '/profile/personal-info',
        },
        {
            titleKey: 'footer.profileSettings',
            url: '/profile/settings',
        },
    ],
};

export const DEFAULT_LINK_ACTIVE_MATCH_OPTIONS: IsActiveMatchOptions = {
    queryParams: 'ignored',
    matrixParams: 'subset',
    fragment: 'exact',
    paths: 'subset',
};

export const SW_ORG_TIN = '7707459899';

@Injectable({
    providedIn: 'root',
})
export class ConfigurationService extends BazisConfigurationService {
    protected configuration = {
        tiles: {
            default: {
                url: 'https://{s}.map.asu.big3.ru/street/{z}/{x}/{y}.png',
                size: 256,
            },
            sputnik: {
                url: 'https://api.mapbox.com/styles/v1/ecofuturetech/clask407v00du14mp9ph04mc1/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiZWNvZnV0dXJldGVjaCIsImEiOiJjbGFydGV0bXgwN3piM25xcXQ4bG90NHRmIn0.OKs5DHMn_L_ob24P49O3qw',
                size: 256,
            },
        },
        emptyInputValue: '-',
        defaultLocation: [55.75222, 37.61556],
        contacts: { email: 'info@agrogo.ru', phone: '+7 495 198-07-70' },
        vehicleNumberMask: ['VVVVVV', '009'],
        vehicleNumberDisplay: ['VVVV', 'VV', '009'],
    };

    selectedLanguage = SELECTED_LANGUAGE;

    availableLanguages = AVAILABLE_LANGUAGES;

    settingsUrl = 'agrogo/settings';

    get vehicleNumberDisplay(): any {
        return this.configuration?.vehicleNumberDisplay || null;
    }
}
